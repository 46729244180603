export const REGISTER_PENDING = 'Registro Pendente'
export const REGISTER_FINISHED = 'Registro Finalizado'
export const REGISTER_CANCELED = 'Registro Cancelado'

export const REGISTERS_STATUS = [REGISTER_PENDING, REGISTER_FINISHED, REGISTER_CANCELED]

export const registersStatusProps = {
  [REGISTER_PENDING]: { color: 'yellow-8', name: 'schedule' },
  [REGISTER_FINISHED]: { color: 'positive', name: 'check' },
  [REGISTER_CANCELED]: { color: 'negative', name: 'close' }
}

export const PENDING = 'Pendente'
export const PROGRESS = 'Em Execução'
export const COMPLETED = 'Concluído'
export const CANCELED = 'Cancelado'
export const REJECTED = 'Rejeitado'
export const SENT = 'Enviado'
export const LATE = 'Atrasado'
export const WAITING = 'Aguardando'
export const REPLIED = 'Respondido'

export const registersStepsStatus = [PENDING, PROGRESS, COMPLETED, CANCELED, REJECTED, SENT, LATE, WAITING, REPLIED]

export const registersStepsStatusProps = {
  [PENDING]: { color: 'yellow-8', name: 'schedule' },
  [PROGRESS]: { color: 'cyan', name: 'autorenew' },
  [COMPLETED]: { color: 'positive', name: 'check' },
  [CANCELED]: { color: 'grey', name: 'close' },
  [REJECTED]: { color: 'red-8', name: 'block' },
  [SENT]: { color: 'indigo-5', name: 'mark_email_read' },
  [LATE]: { color: 'red-6', name: 'watch_later' },
  [WAITING]: { color: 'teal-4', name: 'watch_later' },
  [REPLIED]: { color: 'blue-grey-13', name: 'assignment' }
}

export const MINUTA = 'draft'
export const VIABILIDADE = 'viability'
export const RECEITA_FEDERAL = 'irs'
export const INSCRICAO_JUNTA = 'board_registration'
export const TAXA_JUNTA = 'board_fee'
export const ASSINATURA_DIGITAL = 'digital_signature'
export const PROTOCOLO = 'protocol'
export const SIMPLES_NACIONAL = 'national_simple'

export const steps = [
  MINUTA,
  VIABILIDADE,
  RECEITA_FEDERAL,
  INSCRICAO_JUNTA,
  TAXA_JUNTA,
  ASSINATURA_DIGITAL,
  PROTOCOLO,
  SIMPLES_NACIONAL
]

const simplesNacionalDescription = 'Agora que sua PJ saiu, precisamos fazer a inscrição no Simples Nacional. Porém, para fazer essa solicitação, iremos precisar do número do seu título de eleitor ou do número de recibo do imposto de renda de pessoa física, caso tenha declarado nos últimos dois anos.'

export const stepsProps = {
  [MINUTA]: {
    label: 'Minuta',
    homeLabel: 'Elaboração da Minuta Contratual',
    description: 'Nessa etapa, elaboramos a Minuta Contratual, que é a "certidão de nascimento" da sua empresa. Ela contém informações pessoais do(s) sócio(s), bem como cláusulas obrigatórias e particulares que traduzem as diretrizes do negócio. É importante conferir todos os dados antes de seguir para as próximas etapas.',
    statusOptions: [PENDING, PROGRESS, WAITING, COMPLETED, CANCELED, REJECTED]
  },
  [VIABILIDADE]: {
    label: 'Viabilidade',
    homeLabel: 'Consulta de Viabilidade na Junta Comercial',
    description: 'Aqui iniciamos a consulta de viabilidade no site da Junta Comercial do respectivo Estado.',
    statusOptions: [PENDING, PROGRESS, COMPLETED, CANCELED, REJECTED]
  },
  [RECEITA_FEDERAL]: {
    label: 'Receita Federal',
    homeLabel: 'Cadastro na Receita Federal',
    description: 'Nessa etapa, preenchemos um documento denominado DBE (Documento Básico de Entrada) com informações complementares para a Receita Federal.',
    statusOptions: [PENDING, PROGRESS, COMPLETED, CANCELED, REJECTED]
  },
  [INSCRICAO_JUNTA]: {
    label: 'Inscrição na Junta',
    homeLabel: 'Inscrição na Junta Comercial',
    description: 'Nesse etapa, preenchemos a FCN (Ficha de Cadastro Nacional), onde informamos informações dos sócios para a Junta Comercial. Com isso, será possível dar andamento no processo de abertura da sua empresa.',
    statusOptions: [PENDING, PROGRESS, COMPLETED, CANCELED, REJECTED]
  },
  [TAXA_JUNTA]: {
    label: 'Taxa da Junta',
    homeLabel: 'Pagamento da Taxa da Junta Comercial',
    description: 'Nessa etapa, é necessário que você pague o boleto da Taxa da Junta Comercial, que é o valor cobrado pela Junta Comercial por ter gerado o Número de Identificação de Registro da sua Empresa (principal documento para conseguir obter um CNPJ).',
    statusOptions: [PENDING, SENT, LATE, COMPLETED, CANCELED]
  },
  [ASSINATURA_DIGITAL]: {
    label: 'Assinatura Digital',
    homeLabel: 'Assinatura Digital do Contrato Social',
    description: 'Nessa etapa, será preciso assinar digitalmente a Minuta Contratual, elaborada no início do processo. Para isso, é utilizado certificado digital do tipo A1, A3 ou em nuvem (exemplos: bird id, vidaas).',
    statusOptions: [PENDING, SENT, COMPLETED, CANCELED]
  },
  [PROTOCOLO]: {
    label: 'Protocolo',
    homeLabel: 'Protocolação',
    description: 'Agora é só aguardar a análise da Junta Comercial!',
    statusOptions: [PENDING, WAITING, COMPLETED, REJECTED]
  },
  [SIMPLES_NACIONAL]: {
    label: 'Solicitação do Simples',
    homeLabel: 'Solicitação do Simples',
    description: simplesNacionalDescription,
    statusOptions: [PENDING, WAITING, REPLIED, COMPLETED, CANCELED]
  }
}

export const getIconProps = (status, step) => {
  if (status === REJECTED) return { color: 'negative', icon: 'warning' }
  if (step === TAXA_JUNTA && status === SENT) return { color: 'negative', icon: 'monetization_on' }
  if (step === TAXA_JUNTA && status === LATE) return { color: 'negative', icon: 'monetization_on' }
  if (step === ASSINATURA_DIGITAL && status === SENT) return { color: 'negative', icon: 'draw' }
  if (step === SIMPLES_NACIONAL && status === WAITING) return { color: 'negative', icon: 'watch_later' }

  const statusProps = registersStepsStatusProps[status]

  return { color: statusProps.color, icon: statusProps.name }
}

export const REGISTER_DOCS_TYPES = [
  { name: 'contrato_social', label: 'Contrato Social', required: true },
  { name: 'alteracoes_contratuais', label: 'Outras alterações contratuais', required: false },
  { name: 'cartao_cnpj', label: 'Cartão CNPJ', required: true }
]

export const DEFAULT_PARTNER = {
  name: '',
  nationality: 'brasileiro (a)',
  marital_status: '',
  matrimonial_regime: null,
  profession: 'médico (a)',
  birth_city: '',
  birth_date: '',
  cpf: '',
  rg: '',
  issuing_uf_rg: '',
  issuing_agency: '',
  issuing_date_rg: '',
  address: {
    cep: '',
    state: '',
    city: '',
    neighborhood: '',
    street: '',
    number: '',
    state_code: '',
    municipal_code: ''
  }
}

export const DEFAULT_CNAES = [
  {
    code: '8630-5/02',
    text: 'Atividade médica ambulatorial com recursos para realização de exames complementares'
  },
  {
    code: '8630-5/01',
    text: 'Atividade médica ambulatorial com recursos para realização de procedimentos cirúrgicos'
  },
  {
    code: '8630-5/03',
    text: 'Atividade médica ambulatorial restrita a consultas'
  },
  {
    code: '8630-5/99',
    text: 'Atividades médicas em unidades hospitalares e consultórios de terceiros'
  },
  {
    code: '8610-1/01',
    text: 'Atividades de atendimento hospitalar, exceto pronto socorro e unidades para atendimento a urgências'
  },
  {
    code: '8610-1/02',
    text: 'Atividades de atendimento em pronto socorro e unidades hospitalares para atendimento a urgências;'
  },
  {
    code: '8621-6/02',
    text: 'Serviços móveis de atendimento a urgências, exceto por uti móvel.'
  }
]

export const DEFAULT_SHARE_CAPITAL = 1500

export const DEFAULT_SHARES = 150

export const DEFAULT_MINUTA = {
  doctors: [],
  company_name: '',
  address: {
    cep: '',
    state: '',
    city: '',
    neighborhood: '',
    street: '',
    number: '',
    complement: ''
  },
  cnaes: [],
  company: {
    share_capital: DEFAULT_SHARE_CAPITAL,
    shares: DEFAULT_SHARES
  }
}

export const MAX_COMMENT_FIELD_LENGTH = 1000
