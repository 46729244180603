export const BASIC_DATA = 'Dados Básicos'
export const SELECT_PLAN = 'Escolha do Plano'
export const CHECK_PLAN = 'Conferir Plano'
export const PAY_PLAN = 'Pagamento'
export const ACCOUNT_TYPE = 'Tipo de Conta' // TODO: Essa etapa não existe mais (agora é tipo de empresa). Tratar os lugares que usam essa constante.
export const COMPANY_TYPE = 'Tipo de Empresa'
export const NEW_COMPANY_DATA = 'Dados da Empresa (Abertura)'
export const MIGRATED_COMPANY_DATA = 'Dados da Empresa (Migração)'
export const PARTNER_DATA = 'Dados dos Sócios'
export const DOCTOR_ADDRESS = 'Endereço do Médico'
export const NEW_COMPANY_DOCS = 'Documentação (Abertura)'
export const MIGRATED_COMPANY_DOCS = 'Documentação (Migração)'
export const NATURAL_PERSON_DOCS = 'Documentação (Pessoa Física)'
export const CONCLUDED_WIZARD = 'Cadastro Completo'
export const FINISHED_WIZARD = 'Wizard Finalizado'
export const REGISTER = 'Registro'

export const WIZARD_DATA = 'Dados da Empresa'
export const WIZARD_DOCS = 'Documentação'

export const NEW_COMPANY_STEPS = [BASIC_DATA, SELECT_PLAN, CHECK_PLAN, PARTNER_DATA, DOCTOR_ADDRESS, PAY_PLAN, COMPANY_TYPE, NEW_COMPANY_DATA, NEW_COMPANY_DOCS, CONCLUDED_WIZARD, FINISHED_WIZARD]
export const MIGRATED_COMPANY_STEPS = [BASIC_DATA, SELECT_PLAN, CHECK_PLAN, PARTNER_DATA, DOCTOR_ADDRESS, PAY_PLAN, COMPANY_TYPE, MIGRATED_COMPANY_DATA, MIGRATED_COMPANY_DOCS, CONCLUDED_WIZARD, FINISHED_WIZARD]
export const NATURAL_PERSON_STEPS = [BASIC_DATA, SELECT_PLAN, CHECK_PLAN, PARTNER_DATA, DOCTOR_ADDRESS, PAY_PLAN, NATURAL_PERSON_DOCS, CONCLUDED_WIZARD, FINISHED_WIZARD]

export const PWA_WIZARD_STEPS = [BASIC_DATA, PARTNER_DATA, DOCTOR_ADDRESS, COMPANY_TYPE, WIZARD_DATA, WIZARD_DOCS, CONCLUDED_WIZARD, FINISHED_WIZARD]

export const WIZARD_STEP_COLORS = {
  [COMPANY_TYPE]: 'blue-10',
  [WIZARD_DATA]: 'light-blue',
  [PARTNER_DATA]: 'orange',
  [WIZARD_DOCS]: 'red-6',
  [CONCLUDED_WIZARD]: 'positive',
  [REGISTER]: 'indigo-10',
  [PAY_PLAN]: 'teal-14',
  [SELECT_PLAN]: 'purple-5',
  [CHECK_PLAN]: 'pink-12',
  [DOCTOR_ADDRESS]: 'brown-6'
}

export const NEW_COMPANY_TYPE = 'Nova Empresa'
export const MIGRATED_COMPANY_TYPE = 'Migrar Empresa'
export const NATURAL_PERSON_TYPE = 'Pessoa Física'
export const FREE_TRIAL = 'Teste Grátis'

export const COMPANY_TYPES = [NEW_COMPANY_TYPE, MIGRATED_COMPANY_TYPE, NATURAL_PERSON_TYPE]

export const COMPANY_TYPES_COLORS = {
  [NEW_COMPANY_TYPE]: 'indigo-14',
  [MIGRATED_COMPANY_TYPE]: 'amber-7',
  [NATURAL_PERSON_TYPE]: 'teal-13',
  [FREE_TRIAL]: 'red-6'
}

export const FILE_TYPE_COMPROVANTE_RESIDENCIA_MEDICA = 'comprovante_residencia_medica'
export const FILE_TYPE_ESPELHO_IPTU = 'espelho_iptu'

export const FILE_PROPS_ESPELHO_IPTU = { name: FILE_TYPE_ESPELHO_IPTU, label: 'Espelho do IPTU (opcional)', required: false, iconTooltip: 'Caso o endereço da PJ não seja o da Docstage, é obrigatório o envio deste documento.' }

export const NATURAL_PERSON_DOCS_TYPES = [
  { name: 'titulo_eleitor', label: 'Título de Eleitor ou Recibo do IR', required: true },
  { name: 'cpf', label: 'CNH ou cartão de CPF', required: true },
  { name: 'identidade', label: 'RG', required: true },
  { name: 'comprovante_residencia', label: 'Comprovante de Residência', required: true },
  { name: FILE_TYPE_COMPROVANTE_RESIDENCIA_MEDICA, label: 'Comprovante de Residência Médica', required: false, iconTooltip: 'Anexe seu comprovante de residência de no mínimo 60h semanais' },
  { name: 'crm_pessoal', label: 'CRM Pessoal ou Declaração de Inscrito (opcional)', required: false },
  { name: 'certidao_casamento', label: 'Certidão de Casamento (opcional)', required: false }
]

export const REGISTER_DOCS_TYPES = [
  { name: 'crm_empresa', label: 'CRM', required: true },
  { name: 'contrato_social', label: 'Contrato Social', required: true },
  { name: 'alteracoes_contratuais', label: 'Outras alterações contratuais', required: false },
  { name: 'cartao_cnpj', label: 'Cartão CNPJ', required: true },
  { name: 'alvara_localizacao', label: 'Prefeitura', required: false },
  { name: 'alvara_sanitario', label: 'Vigilância Sanitária', required: false },
  { name: 'alvara_bombeiros', label: 'Alvará dos Bombeiros', required: false }
]

export const ACCOUNTING_DOCS_TYPES = [
  { name: 'balanco', label: 'Balanço da Empresa', required: false },
  { name: 'dre', label: 'DRE da Empresa', required: false },
  { name: 'balancete', label: 'Balancetes da Empresa', required: false },
  { name: 'folha_pagamento', label: 'Folha de Pagamento', required: false }
]

export const SERVICE_AGREEMENT_DOCS_TYPE = [
  { name: 'contrato_docstage', label: 'Contrato de Serviços Docstage', required: false }
]

export const NEW_COMPANY_DOCS_TYPES = [
  ...NATURAL_PERSON_DOCS_TYPES,
  FILE_PROPS_ESPELHO_IPTU
]

export const MIGRATED_COMPANY_DOCS_TYPES = [
  ...REGISTER_DOCS_TYPES,
  ...ACCOUNTING_DOCS_TYPES,
  FILE_PROPS_ESPELHO_IPTU
]

export const defaultCredentials = {
  password: ''
}

export const defaultUser = {
  name: '',
  email: '',
  password: '',
  phone: ''
}

export const defaultCompany = {
  type: NEW_COMPANY_TYPE,
  cnpj: '',
  name: '',
  certificate_credentials: {
    password: ''
  },
  nf_credentials: {
    login: '',
    password: ''
  },
  extra: {
    suggestions: [],
    tax_address_state: null
  },
  certificate_from_cloud: false
}

export const defaultDoctor = {
  cpf: '',
  rg: '',
  ctps: '',
  birth_city: '',
  college: '',
  marital_status: '',
  birth_date: '',
  address: { cep: '' },
  is_resident_doctor: false,
  serve_armed_forces: false,
  permanent_employee: false,
  has_fies: false,
  certificate_credentials: {
    password: ''
  },
  certificate_from_cloud: false
}

export const defaultAddress = {
  cep: '',
  state: '',
  city: '',
  neighborhood: '',
  street: '',
  number: '',
  state_code: '',
  municipal_code: ''
}

export const defaultCoupon = {
  max_payments: null,
  name: null,
  percentage: null
}

export const defaultPlan = {
  is_custom: false,
  is_containment_plan: false,
  max_payments: null,
  name: '',
  services: [],
  type: '',
  value: null,
  value_residents: null

}

export const defaultOrderData = {
  coupon: { ...defaultCoupon },
  is_indication: false,
  is_resident: false,
  order_value: null,
  plan: { ...defaultPlan }
}

export const defaultWizardData = {
  user: { ...defaultUser },
  company: { ...defaultCompany },
  doctor: { ...defaultDoctor },
  address: { ...defaultAddress },
  order_data: { ...defaultOrderData },
  partners: [],
  current: {}
}
