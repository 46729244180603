export const appHome = [
  {
    path: 'solicitar-nf',
    name: 'App.Home.InvoiceRequest',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/invoices/DoctorInvoiceRequestDialog.vue'),
      dialogName: 'InvoiceRequest',
      dialogEmits: ['onCreate']
    }
  },
  {
    path: 'enviar-extrato/ano/:year/mes/:month',
    name: 'App.Home.UploadStatement',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/home/StatementDialog.vue'),
      dialogName: 'Upload',
      dialogEmits: []
    }
  },
  {
    path: 'empresa/:companyId/enviar-inss',
    name: 'App.Home.Inss',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/home/InssPaycheckDialog.vue'),
      dialogName: 'Inss',
      dialogEmits: []
    }
  },
  {
    path: 'empresa/:companyId/enviar-arquivos-para-ir',
    name: 'App.Home.Ir',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/ir/AddIrFileDialog.vue'),
      dialogName: 'Ir',
      dialogEmits: []
    }
  },
  {
    path: 'cadastrar-tomador',
    name: 'App.Home.NewClient',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/clients/NewClientDialog.vue'),
      dialogName: 'NewClient',
      dialogEmits: ['onCreate']
    }
  },
  {
    path: 'adicionar-plantao',
    name: 'App.Home.NewEvent',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/events/EditEventDialog.vue'),
      dialogName: 'NewEvent',
      dialogEmits: ['onCreate']
    }
  },
  {
    path: 'indicar-amigo',
    name: 'App.Home.NewIndication',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/financial/NewIndicationDialog.vue'),
      dialogName: 'NewIndication',
      dialogEmits: []
    }
  },
  {
    path: 'nota-fiscal/:accountReceivableId',
    name: 'App.Home.InvoiceDetails',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/invoices/InvoiceDetailsDialog.vue'),
      dialogName: 'InvoiceDetails',
      dialogEmits: []
    }
  },
  {
    path: 'evento/:eventId/editar',
    name: 'App.Home.Calendar.Edit',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/events/EditEventDialog.vue'),
      dialogName: 'EditEvent',
      dialogEmits: ['onUpdate', 'onDelete']
    }
  },
  {
    path: 'boleto/:boletoId',
    name: 'App.Home.BoletoDetails',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/payments/PaymentDetailsDialog.vue'),
      dialogName: 'BoletoDetails',
      dialogEmits: []
    }
  },
  {
    path: 'boletos-atrasados',
    name: 'App.Home.LateBoletos',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/payments/LateBoletosDialog.vue'),
      dialogName: 'LateBoletos',
      dialogEmits: ['onRequestSecondCopy']
    }
  }
]

export const appInvoices = [
  {
    path: 'criar',
    name: 'App.Invoices.Create',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/invoices/DoctorInvoiceRequestDialog.vue'),
      dialogName: 'Create',
      dialogEmits: ['onCreate']
    }
  }
]

export const appBoletos = [
  {
    path: 'empresa/:companyId/boleto/:boletoId/historico',
    name: 'App.Boletos.History',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/payments/DoctorBoletoHistoryDialog.vue'),
      dialogName: 'History',
      dialogEmits: []
    }
  }
]

export const appClients = [
  {
    path: 'criar',
    name: 'App.Clients.Create',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/clients/NewClientDialog.vue'),
      dialogName: 'Create',
      dialogEmits: ['onCreate']
    }
  },
  {
    path: 'detalhes/:clientId',
    name: 'App.Clients.Details',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/clients/ClientDetailsDialog.vue'),
      dialogName: 'Details',
      dialogEmits: []
    }
  }
]

export const appCashBook = [
  {
    path: 'criar',
    name: 'App.CashBook.Create',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/cashBook/AddCashBookDialog.vue'),
      dialogName: 'Create',
      dialogEmits: ['onAdd']
    }
  },
  {
    path: 'detalhes/:cashBookId',
    name: 'App.CashBook.Details',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/cashBook/CashBookDetailsDialog.vue'),
      dialogName: 'Details',
      dialogEmits: []
    }
  }
]

export const appReceipt = [
  {
    path: 'criar',
    name: 'App.Receipt.Create',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/receipt/ReceiptCreateEditDialog.vue'),
      dialogName: 'Create',
      dialogEmits: ['onCreate']
    }
  },
  {
    path: 'assinatura',
    name: 'App.Receipt.Signature',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/receipt/ReceiptSignatureDialog.vue'),
      dialogName: 'Create',
      dialogEmits: ['onCreate']
    }
  },
  {
    path: 'detalhes/:receiptId',
    name: 'App.Receipt.Details',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/receipt/ReceiptDetailsDialog.vue'),
      dialogName: 'Details',
      dialogEmits: []
    }
  }
]

export const appStatements = [
  {
    path: 'enviar/ano/:year/mes/:month',
    name: 'App.Statements.Upload',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/home/StatementDialog.vue'),
      dialogName: 'Upload',
      dialogEmits: []
    }
  }
]

export const appCalendar = [
  {
    path: 'criar',
    name: 'App.Calendar.Create',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/events/EditEventDialog.vue'),
      dialogName: 'Create',
      dialogEmits: ['onCreate']
    }
  },
  {
    path: 'editar/:eventId',
    name: 'App.Calendar.Edit',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/events/EditEventDialog.vue'),
      dialogName: 'Edit',
      dialogEmits: ['onUpdate', 'onDelete']
    }
  }
]

export const appCashFlow = [
  {
    path: 'nota-fiscal/:accountReceivableId',
    name: 'App.CashFlow.InvoiceDetails',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/invoices/InvoiceDetailsDialog.vue'),
      dialogName: 'InvoiceDetails',
      dialogEmits: []
    }
  },
  {
    path: 'boleto/:boletoId',
    name: 'App.CashFlow.BoletoDetails',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/payments/PaymentDetailsDialog.vue'),
      dialogName: 'BoletoDetails',
      dialogEmits: []
    }
  }
]

export const appReports = [
  {
    path: 'solicitar-nf',
    name: 'App.Reports.InvoiceRequest',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/invoices/DoctorInvoiceRequestDialog.vue'),
      dialogName: 'InvoiceRequest',
      dialogEmits: ['onCreate']
    }
  }
]

export const dashboardBoletos = [
  {
    path: 'editar/:boletoId',
    name: 'Dashboard.Boletos.Edit',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/payments/EditPaymentDialog.vue'),
      dialogName: 'Edit',
      dialogEmits: []
    }
  }
]

export const dashboardPlans = [
  {
    path: 'detalhes/:planId',
    name: 'Dashboard.Plans.Details',
    component: () => import('components/dialogs/common/DialogRouterView.vue'),
    meta: {
      dialogComponent: () => import('src/components/dialogs/plans/PlanDetailsDialog.vue'),
      dialogName: 'Details',
      dialogEmits: []
    }
  }
]
